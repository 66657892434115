<template lang="pug">
.main-page.row
  el-form.demo-form-inline(:inline="true" :model="searchForm")
    el-form-item(label="角色名称")
      el-input(v-model="searchForm.name")
    el-form-item
      el-button(type="primary" @click="refresh" v-loading="loading") 查询
      el-button(type="success" @click="generate" v-if="pemission['2.2.1']") 新建角色

  el-table(
    :data="tableData"
    stripe
    fit
    width="800"
    v-loading="loading"
  )
    //- el-table-column(prop="date" label="序号")
    el-table-column(prop="role_name" label="角色")
    el-table-column(prop="ctime" label="创建时间")
    el-table-column(prop="cusername" label="创建人")
    el-table-column(prop="name" label="操作" fixed="right" width="160" align="center")
      template(slot-scope="scope")
        el-button(
          @click="handleClick(scope.row, 'edit')"
          type="primary" size="small"
        ) 编辑
        el-button(
          v-if="pemission['2.2.2']"
          @click="handleClick(scope.row, 'delete')"
          type="danger"
          size="small"
        ) 删除

  el-pagination(
    background
    layout="prev, pager, next, total"
    :page-size="pageSize"
    :total="total"
    @current-change="pageChange"
  )

  el-dialog(
    title="生成角色"
    :visible.sync="generateVisible"
    width="50%"
    :show-close="false"
  )
    .row(v-if="generateVisible")
      el-form.demo-form-inline(:model="generateForm" label-width="120px")
        el-col(:span="12")
          el-form-item(label="角色名称")
            el-input(v-model="generateForm.role_name")
        el-col(:span="24")
          el-form-item(label="权限")
            el-tree(
              ref="tree"
              :data="treeData"
              :props="defaultProps"
              :default-expanded-keys="['1', '2', '3']"
              :default-checked-keys="generateForm.nodes"
              show-checkbox
              node-key="id"
            )

    span(slot="footer" class="dialog-footer")
      el-button(@click="generateVisible = false") 取 消
      el-button(
        type="primary"
        @click="handleGenerate"
      ) 生成
</template>

<script>
import BaseSelect from '@/components/BaseSelect.vue';
import { pemissionTree, getPemission } from '@/utils/const';
import request from '@/utils/request';

export default {
  components: {
    BaseSelect,
  },
  data() {
    return {
      pemission: getPemission(),
      searchForm: {
      },
      loading: false,
      tableData: [],
      total: 0,
      pageSize: 20,
      pageIndex: 1,
      multipleSelection: [],
      generateVisible: false,
      generateForm: {
        nodes: [],
      },
      treeData: pemissionTree,
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      isEdit: false,
    };
  },
  methods: {
    refresh() {
      this.generateVisible = false;
      this.pageIndex = 1;
      this.search();
    },
    search() {
      const params = {
        ...this.searchForm,
        page: this.pageIndex,
        pagesize: this.pageSize,
      };
      this.getData(params);
    },
    getData(params) {
      this.loading = true;
      request({
        method: 'get',
        url: '/manager/role/getList',
        params,
      })
        .then((res) => {
          if (res.code === 1000) {
            this.total = res.data.count;
            this.tableData = res.data.list.map((e) => ({
              ...e,
            }));
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },
    generate() {
      this.isEdit = false;
      this.generateForm = {};
      this.generateVisible = true;
    },
    handleGenerate() {
      if (!this.generateForm.role_name) {
        this.$message('请输入角色名称');
        return;
      }
      const params = {
        role_name: this.generateForm.role_name,
        id: this.generateForm.id,
        nodes: this.$refs.tree.getCheckedKeys(),
      };
      const url = `/manager/role/${this.isEdit ? 'editRole' : 'addRole'}`;
      request({
        method: 'post',
        url,
        data: params,
      })
        .then((res) => {
          if (res.code === 1000) {
            this.$message.success('保存成功');
            this.refresh();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },
    selectDelete() {},
    handleClick(row, target) {
      if (target === 'edit') {
        this.isEdit = true;
        this.generateForm = {
          ...row,
          nodes: JSON.parse(row.nodes),
          id: row.id,
        };
        this.generateVisible = true;
      }
      if (target === 'delete') {
        this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        }).then(() => {
          this.doDelete(row.id);
        }).catch(() => {
          this.$message.info('已取消');
        });
      }
    },

    doDelete(id) {
      request({
        method: 'get',
        url: '/manager/role/delete',
        params: { role_id: id },
      })
        .then((res) => {
          if (res.code === 1000) {
            this.refresh();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },

    pageChange(e) {
      this.pageIndex = e;
      this.search();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style>

</style>
